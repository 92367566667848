import {
    Button,
    CircularProgress,
    CircularProgressLabel,
    Divider,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    Spacer,
    Tag,
    Text,
    useColorModeValue,
    useToast,
    VStack,
    Wrap
} from "@chakra-ui/react";
import { CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { FiBox } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { formatEther } from "viem";
import {
    useAccount,
    useChainId,
    useWaitForTransactionReceipt,
    useWriteContract
} from "wagmi";
import CounterComponent from "../../../components/Counter";
import {
    IfasstStakingUpgradeableABI,
    IfasstStakingUpgradeableDeploymentDetails
} from "../../../constants/contractDeploymentDetails/IfasstStakingUpgradeable";
import { ContractDeploymentObjectType } from "../../../constants/types";
import {
    useGetStakingUserAccount,
    useGetUserPendingStakingRewards
} from "../../../hooks/stakingHooks";
import { StructStakingStructOutput } from "../../../../web3/contracts/typechain-types/contracts/IfasstUpgradeable";
import { investmentPlansObjectArray } from "../../../constants/contractsConfig";

const StakingCardComponent = ({
    stakingObject,
    stakingContractObject
}: {
    stakingObject: StructStakingStructOutput;
    stakingContractObject: ContractDeploymentObjectType;
}) => {
    const investmentPlansObject =
        Number(stakingObject?.investmentPlan?.planId) > 0
            ? investmentPlansObjectArray[
            Number(stakingObject?.investmentPlan?.planId) - 1
            ]
            : undefined;
    // const stakingWithReward = useGetStakingById(stakingId);
    // @ts-ignore
    const toast = useToast();
    const pendingReward = stakingObject?.pendingReward;

    console.log("stakingObject", stakingObject);

    const apy =
        (Number(investmentPlansObject?.per) /
            Number(investmentPlansObject?.duration)) *
        86400 *
        12 *
        30;

    console.log("stakingObject", stakingObject);

    const totalStakingReward = stakingObject
        ? Number(
            stakingObject?.valueInUSD * stakingObject?.investmentPlan?.per
        ) / 100
        : 0;

    const rewardClaimedPer = pendingReward
        ? (Number(pendingReward) * 100) / totalStakingReward
        : 0;

    const [transactionState, setTransactionState] = useState<
        "Pending" | "Awaiting Confirmation" | "Success" | undefined
    >(undefined);

    const {
        writeContractAsync: writeContractAsyncUnstake,
        data: dataUnstake,
        error: errorUnstake,
        status: statusUnstake,
        isPending: isPendingUnstake,
        reset: resetUnstake
    } = useWriteContract();

    const {
        data: dataTransactionHash,
        error: errorTransactionHash,
        isLoading: isLoadingTransactionHash,
        status: statusTransactionHash
    } = useWaitForTransactionReceipt({
        hash: dataUnstake
    });

    useEffect(() => {
        if (isPendingUnstake) {
            setTransactionState("Awaiting Confirmation");
        } else if (statusUnstake === "error") {
            setTransactionState(undefined);
            resetUnstake();
        } else if (isLoadingTransactionHash) {
            setTransactionState("Pending");
        } else if (statusTransactionHash === "success") {
            toast({
                title: `Congratulation`,
                description:
                    "Transaction is successfull. You may go to Dashboard for stats.",
                status: "success",
                icon: <CheckCircle />,
                isClosable: true
            });

            setTransactionState(undefined);
            resetUnstake();
        } else {
            setTransactionState(undefined);
        }
    }, [
        resetUnstake,
        statusUnstake,
        statusTransactionHash,
        isLoadingTransactionHash,
        isPendingUnstake,
        dataUnstake,
        dataTransactionHash,
        toast
    ]);

    const confirmTransactionUnstake = async () => {
        try {
            // @ts-ignore
            await writeContractAsyncUnstake({
                address: stakingContractObject?.proxyAddress as `0x${string}`,
                abi: stakingContractObject?.abi,
                functionName: "reArrange",
                args: [stakingObject?.id]
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Tag
            w={["full", 250]}
            borderWidth="thin"
            borderRadius="3xl"
            minH={350}
            p={5}
            gap={5}
            flexDirection={"column"}
            bgColor={useColorModeValue("transparent", "whiteAlpha.200")}
        >
            <HStack w="full">
                <Heading size="sm" color="twitter.500">
                    #Staking Id: {Number(stakingObject?.id) + 2100}
                </Heading>
                <Spacer />
                <Tag
                    size="md"
                    colorScheme={stakingObject?.isActive ? "green" : "red"}
                >
                    {stakingObject?.isActive ? "Active" : "Expired"}
                </Tag>
            </HStack>
            <Divider />
            <Tag colorScheme="twitter">Value Staked</Tag>
            <HStack>
                <Heading size="lg">
                    $
                    {Number(
                        formatEther(stakingObject?.valueInUSD ?? BigInt(0))
                    )?.toFixed(2)}
                </Heading>
                <Text as="span" fontSize="sm">
                    USD
                </Text>
            </HStack>
            <Tag colorScheme="green">APY</Tag>
            <VStack spacing={0}>
                <Heading lineHeight={1.2}>
                    {/* {Number(stakingObject?.investmentPlan?.per ?? 0)}% */}
                    {apy?.toFixed(0)}%
                </Heading>
                <Text textStyle={"bold"}>
                    {Number(apy / 12)?.toFixed(0)}% Per Month
                </Text>
            </VStack>
            <Tag colorScheme="green">Reward Claimed</Tag>
            <CircularProgress
                value={Number(rewardClaimedPer?.toFixed(0))}
                // @ts-ignore
                size={["150px", "125px"]}
                color="green.400"
            >
                <CircularProgressLabel fontSize={20}>
                    <Text fontWeight={900}>
                        $
                        {Number(
                            formatEther(
                                stakingObject?.rewardClaimed ?? BigInt(0)
                            )
                        )?.toFixed(2)}
                    </Text>
                    <Text fontSize={"sm"}>USD</Text>
                </CircularProgressLabel>
            </CircularProgress>
            <Tag colorScheme="yellow">Pending Rewards</Tag>
            <Text>
                ${Number(formatEther(pendingReward ?? BigInt(0)))?.toFixed(10)}{" "}
                USD
            </Text>
            {/* <Button
                colorScheme="yellow"
                bgColor="yellow.300"
                borderRadius={'full'}
                size="sm"
            >
                Claim Pending Reward
            </Button> */}
            <Tag colorScheme="pink">Time Remaining</Tag>
            <CounterComponent
                endTime={Number(
                    (stakingObject?.startTime ?? BigInt(0)) +
                    (stakingObject?.investmentPlan?.duration ?? BigInt(0))
                )}
            />
            {/*<Button
                colorScheme="pink"
                bgColor="pink.300"
                w="full"
                maxW={200}
                borderRadius={"full"}
                onClick={confirmTransactionUnstake}
                isLoading={
                    transactionState === "Awaiting Confirmation" ||
                    transactionState === "Pending"
                }
                isDisabled
            >
                UnStake
            </Button>*/}
            {/* <Text color="red" fontSize={"xx-small"}>
                * Unstake is temporary disabled
            </Text> */}
        </Tag>
    );
};

const StakingsDashboard = () => {
    const { address } = useAccount();
    const { userAddress } = useParams<{
        userAddress: `0x${string}` | undefined;
    }>();

    const toast = useToast();

    const chainId = useChainId();

    const stakingContractObject =
        IfasstStakingUpgradeableDeploymentDetails[chainId];

    const currentUser = userAddress ?? address;
    const userStakingAccount = useGetStakingUserAccount(currentUser);
    const stakingsArray = userStakingAccount?.data?.stakings;
    console.log("userStakingAccount", userStakingAccount)
    const userPendingStakingRewards =
        useGetUserPendingStakingRewards(currentUser);
    const stakingContractABI = IfasstStakingUpgradeableABI;

    const totalStakingValue = stakingsArray?.reduce(
        (totalValue: number, staking: StructStakingStructOutput) =>
            totalValue +
            Number(
                formatEther(
                    staking?.isActive
                        ? staking?.valueInUSD
                        : (BigInt(0) ?? BigInt(0))
                )
            ),
        0
    );

    const {
        writeContractAsync,
        data: dataRegister,
        error: errorRegister,
        status: statusRegister,
        isPending: isPendingRegister,
        reset: resetRegister
    } = useWriteContract();

    const {
        data: dataTransactionHash,
        error: errorTransactionHash,
        isLoading: isLoadingTransactionHash,
        status: statusTransactionHash
    } = useWaitForTransactionReceipt({
        hash: dataRegister
    });

    const confirmTransactionClaim = async () => {
        try {
            // @ts-ignore
            await writeContractAsync({
                address: stakingContractObject?.proxyAddress as `0x${string}`,
                abi: stakingContractABI,
                functionName: "claimAllStakingsReward",
                args: [address!]
            });
        } catch (err) {
            console.log(err);
        }
    };

    const [transactionState, setTransactionState] = useState<
        "Pending" | "Awaiting Confirmation" | "Success" | undefined
    >(undefined);

    useEffect(() => {
        if (isPendingRegister) {
            setTransactionState("Awaiting Confirmation");
        } else if (statusRegister === "error") {
            setTransactionState(undefined);
            resetRegister();
        } else if (isLoadingTransactionHash) {
            setTransactionState("Pending");
        } else if (statusTransactionHash === "success") {
            toast({
                title: `Congratulation`,
                description:
                    "Transaction is successfull. You may go to Dashboard for stats.",
                status: "success",
                icon: <CheckCircle />,
                isClosable: true
            });

            setTransactionState(undefined);
            resetRegister();
        } else {
            setTransactionState(undefined);
        }
    }, [
        resetRegister,
        statusRegister,
        statusTransactionHash,
        isLoadingTransactionHash,
        isPendingRegister,
        dataRegister,
        dataTransactionHash,
        toast
    ]);

    return (
        <VStack spacing={10} w="full" minH={"100vh"}>
            <HStack>
                <Heading>Stakings</Heading>
                <Icon as={FiBox} boxSize={10}></Icon>
            </HStack>
            <SimpleGrid columns={{ base: 2 }} gap={2} w="full">
                <VStack
                    borderWidth={"thin"}
                    p={5}
                    borderRadius="3xl"
                    w="full"
                    minH={150}
                >
                    <Tag textAlign="center" p={1}>
                        Total Staked Value
                    </Tag>
                    <Heading size="lg">
                        ${(totalStakingValue ?? 0)?.toFixed(2)} USD
                    </Heading>
                </VStack>
                <VStack
                    borderWidth={"thin"}
                    p={5}
                    borderRadius="3xl"
                    w="full"
                    minH={150}
                >
                    <Tag textAlign="center" p={1}>
                        Total Staking Pending Rewards
                    </Tag>
                    <Text fontWeight={900} fontSize="lg">
                        $
                        {Number(
                            formatEther(
                                userPendingStakingRewards?.data ?? BigInt(0)
                            )
                        )?.toFixed(5)}{" "}
                        USD
                    </Text>
                    <Button
                        w={100}
                        onClick={confirmTransactionClaim}
                        isLoading={
                            transactionState === "Awaiting Confirmation" ||
                            transactionState === "Pending"
                        }
                    >
                        Claim
                    </Button>
                </VStack>
                <VStack
                    borderWidth={"thin"}
                    p={5}
                    borderRadius="3xl"
                    w="full"
                    minH={150}
                >
                    <Tag textAlign="center" p={1}>
                        Total Staking Rewards Claimed
                    </Tag>
                    <Heading fontWeight={900} size={["md"]}>
                        $
                        {Number(
                            formatEther(
                                userStakingAccount?.data?.incomes?.staking ??
                                BigInt(0)
                            )
                        )?.toFixed(5)}{" "}
                        USD
                    </Heading>
                    {/* <Button w={100}>Claim</Button> */}
                </VStack>
                <VStack
                    borderWidth={"thin"}
                    p={5}
                    borderRadius="3xl"
                    w="full"
                    minH={150}
                >
                    <Tag textAlign="center" p={1}>
                        Total Stakings
                    </Tag>
                    <Heading fontWeight={900}>
                        {Number(
                            userStakingAccount?.data?.stakings?.length ?? 0
                        )}
                    </Heading>
                    {/* <Button w={100}>Claim</Button> */}
                </VStack>
            </SimpleGrid>
            {stakingsArray && stakingsArray?.length > 0 ? (
                <Wrap justify="center">
                    {stakingsArray?.map(
                        (
                            stakingObject: StructStakingStructOutput,
                            key: number
                        ) => {
                            return (
                                <StakingCardComponent
                                    stakingObject={stakingObject}
                                    stakingContractObject={
                                        stakingContractObject
                                    }
                                    key={key}
                                />
                            );
                        }
                    )}
                </Wrap>
            ) : (
                <Heading color="red">You have no stakings.</Heading>
            )}
        </VStack>
    );
};

export default StakingsDashboard;
